import axios from 'axios';

async function getProductData() {
    try {
        const response = await axios.get('http://localhost:3000/datos');
        return response.data;

    } catch (error) {
        console.log(error);
        return [];
    }
}

export default getProductData;
